
<template>
  <div class="foodDB view">
    <div id="restart-button">re</div>
    <div class="search">
      <input type="search" id="search-input" placeholder="Search..." v-model="searchQuery">
      <button type="submit">
        <svg width="24px" height="23.807983px" viewBox="0 0 24 23.807983" version="1.1"
          xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <path d="M0 0L24 0L24 23.808L0 23.808L0 0Z" id="path_1" />
            <clipPath id="mask_1">
              <use xlink:href="#path_1" />
            </clipPath>
          </defs>
          <g id="8150277_search_magnifier_zoom_magnifying_web_icon">
            <path d="M0 0L24 0L24 23.808L0 23.808L0 0Z" id="Background" fill="currentColor" fill-opacity="0"
              fill-rule="evenodd" stroke="none" />
            <path
              d="M23.5138 21.9394L20.3709 19.3446C22.1889 17.2654 23.189 14.5962 23.1849 11.8343C23.1952 6.42262 19.4405 1.73204 14.158 0.557086C8.87546 -0.617868 3.48658 2.03899 1.202 6.94474C-1.08258 11.8505 0.351932 17.685 4.65086 20.972C8.94979 24.2591 14.9563 24.1142 19.0917 20.6237L22.3809 23.3647C22.5367 23.5043 22.7381 23.5823 22.9474 23.584C23.212 23.5869 23.4624 23.465 23.6235 23.255C23.7994 23.0733 23.8882 22.8244 23.8672 22.5723C23.8462 22.3203 23.7174 22.0895 23.5138 21.9394L23.5138 21.9394ZM1.95144 11.7795C1.95144 6.41052 6.30383 2.05812 11.6728 2.05811C17.0417 2.05811 21.3941 6.41048 21.3941 11.7794C21.3942 17.1484 17.0418 21.5008 11.6728 21.5008C6.30386 21.5008 1.95144 17.1484 1.95144 11.7795L1.95144 11.7795Z"
              id="Shape" fill="currentColor" fill-rule="evenodd" stroke="none" />
          </g>
        </svg>
      </button>
    </div>


    <div class="FoodList">
      <div class="category" v-for="(category,key) in groups">
      <div class="category-title">{{ key }} </div> 

      <div class="category-list">
        <FoodCard v-for="(food, idx) in category" :key="food.id" :food="food" :type="'list'"
        @amountChange="onAmountChange" @editFoodItem="editFood" @animate="animateCard" :ref=food.id></FoodCard>
      </div>

    </div>

      </div>



    <BottomPane :id="'counter'">
      <CounterPane :ingredients="ingredients" :totalCalories="totalCalories"></CounterPane>
    </BottomPane>

    <BottomPane :id="'editFood'">
      <EditFood v-if="editingFood" :food=editingFood></EditFood>
    </BottomPane>

    <div class="warning" v-if="filteredBySearch.length <= 0">
      <span>No results found.</span>
    </div>

  </div>
</template>

<script>
import { gsap } from "gsap"
export default {
  name: 'FoodDB',
  mounted() {
    this.$nextTick(() => {
      console.log(
        this.groupBy(this.foods, 'category')
      )

    })
  },
  data() {
    return {
      editingFood: null,
      animated: false,
      searchQuery: "",
      ingredients: null,
      totalCalories: null
    }
  },
  props: {

  },
  methods: {
    groupBy(array, key) {
      const result = {}
      array.forEach(item => {
        if (!result[item[key]]) {
          result[item[key]] = []
        }
        result[item[key]].push(item)
      })
      return result
    },
    editFood(food) {
      this.showPane('editFood', true)

      this.editingFood = food;

      setTimeout(() => { this.$panes['editFood'].moveToBreak('top') }, 150)

      console.log(this.editingFood)
    },
    animateCard() {
      console.log("cc")
      if (this.animated === false) {
        console.log(this.animated)
        this.animated = true;
        console.log(this.animated)
        console.log(this.$refs)
        let i = document.querySelectorAll(".FoodCard")
        console.log(i)


        let tween = gsap.fromTo(
          i,
          { opacity: 0, y: 25 },
          { opacity: 1, y: 0, duration: 0.5, stagger: 0.15 }
        );
        const restartButton = document.getElementById("restart-button");

        restartButton.addEventListener("click", () => {
          tween.restart();
          //tween.reverse();
        });

      }

    },
    onAmountChange(value) {
      console.log(value) // someValue
      this.showPane('counter', true)
      console.log(this.$refs)
      this.getTotal()
    },
    getTotal() {
      function getNum(val) {
        val = +val || 0
        return val;
      }
      function sumArray(array) {
        //  array.filter( value => !Number.isNaN(value));
        console.log(array)
        let sum = 0;
        array.forEach(item => {
          let r = getNum(item)
          sum += r;
        });
        console.log(sum);
        return sum
      }

      let numbers = []
      Object.values(this.$refs).forEach(val => numbers.push(parseInt(val[0].calculatedCals)));
      console.log(numbers)
      this.totalCalories = sumArray(numbers)

      let items = []
      let a = Object.values(this.$refs).forEach(entry => {
        console.log(entry[0].calculatedCals)
        let n = { ...entry[0].food, calculatedCals: entry[0].calculatedCals }
        console.log(n)
        items.push(n)
      });
      let i = items.filter(food => food.grams > 0);
      console.log(i)
      this.ingredients = i;



    },
  },
  computed: {
    groups() {
      return this.groupBy(this.foods, 'category')
    },
    filteredBySearch: function () {
      let self = this;
      return this.$store.state.foods.filter(
        (item) =>
          item.name
            .toLowerCase()
            .indexOf(self.searchQuery.toLowerCase()) !== -1
      );
    },
    foods() {
      return this.$store.state.foods
    },
  },
  components: {
    FoodCard: () => import("@/components/FoodCard.vue"),
    CounterPane: () => import("@/components/Panes/CounterPane.vue"),
    BottomPane: () => import("@/components/BottomPane.vue"),
    EditFood: () => import("@/components/Panes/EditFood.vue"),

  },
}
</script>

<style lang="scss" scoped>


.FoodList {
  display: flex;
  gap: 12px;
  flex-direction: column;
  height: 100%;
  padding-top: 32px;

  padding-bottom: 150px;
  overflow: auto;
  height: auto;

}
.category {
  .category-title {
    font-weight: 500;
    color: var(--gray-600);
    padding-bottom: 6px;
    font-size: 15px;
}
  .category-list {
    display: flex;
    gap: 12px;
    flex-direction: column;
}


}



.search {
  margin: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  position: sticky;
  top: 0px;
  z-index: 4;
  /* padding: 0 10px; */
  /* padding-top: 40px; */



}

.search {
  vertical-align: baseline;

  margin: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  border-radius: 16px;
  background: #fbfbfb;
  box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  padding: 10px 16px;

  button {
    margin-left: auto;
    background: transparent;
    border-radius: 20px;
    border: 0;

    color: var(--gray-400);
    cursor: pointer;
    outline: none;
    width: 32px;
    height: 32px;
    transition: ease all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    flex: 1;
    outline: none;
    border: 0;
    background: transparent;
    font-size: 0.9rem;
    color: #383838;
    color: var(--gray-500);
    /* font-family: "Montserrat", sans-serif; */
  }

  input::Placeholder {
    color: var(--gray-400);
  }
}
</style>

