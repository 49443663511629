<template>
  <div class="about view">
    <form action="" id="search-filter-form">
      <div class="form-search">
        <div class="input-group">
          <input type="search" id="search-input" placeholder="Search by ID, Name or Type">
          <button type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-search">
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg></button>
        </div>
      </div>
    </form>
    <recipeDetails v-if="recipe" :recipe="recipe" @closeDetails="close()"></recipeDetails>
    <div id="poke-list">
      <div class="card" v-for="recipe in $store.state.recipes" @click="openRecipe(recipe)">

        <div class="card__image"><img :src="recipe.image"></div>
        <div class="card__detail">

          <h4 class="card__name">{{ recipe.name }}</h4>
          <div class="card__types">
            <div>{{ recipe.totalCalories }} kcal</div>
          </div>
        </div>
        <div class="card__number">#1</div>
      </div>
      <!--   <div class="card">
    <div class="card__image"><img src="https://www.pngmart.com/files/15/Food-Plate-Top-View-Nutrition-PNG.png"></div>
    <div class="card__particle"></div>
    <div class="card__detail">
      <h4 class="card__name">Food</h4>
    <div class="card__types">
        <div>400 kcal</div>
      </div>
    </div>
    <div class="card__number">#2</div>
  </div>
  <div class="card" >
    <div class="card__image"><img src="https://www.pngmart.com/files/15/Food-Plate-Top-View-Snack-PNG.png"></div>
    <div class="card__particle"></div>
    <div class="card__detail">
      <h4 class="card__name">Food</h4>
    <div class="card__types">
        <div>550 calories</div>
        <div>poison</div>
      </div>
    </div>
    <div class="card__number">#3</div>
  </div>
  <div class="card">
    <div class="card__image"><img src="https://www.pngmart.com/files/15/Fish-Food-Plate-PNG.png"></div>
    <div class="card__particle"></div>
    <div class="card__detail">
      <h4 class="card__name">Food</h4>
    <div class="card__types">
        <div>380 calories</div>
      </div>
    </div>
    <div class="card__number">#4</div>
  </div>
  <div class="card">
    <div class="card__image"><img src="https://www.pngmart.com/files/15/Food-Plate-Top-View-Cake-PNG.png"></div>
    <div class="card__particle"></div>
    <div class="card__detail">
      <h4 class="card__name">charmeleon</h4>
    <div class="card__types">
        <div>fire</div>
      </div>
    </div>
    <div class="card__number">#5</div>
  </div>
  <div class="card">
    <div class="card__image"><img src="https://static.vecteezy.com/system/resources/previews/009/662/610/large_2x/grilled-half-chicken-with-honey-free-png.png"></div>
    <div class="card__particle"></div>
    <div class="card__detail">
      <h4 class="card__name">charizard</h4>
    <div class="card__types">
        <div>fire</div>
        <div>flying</div>
      </div>
    </div>
    <div class="card__number">#6</div>
  </div>
  <div class="card">
    <div class="card__image"><img src="https://cdn.sitedish.nl/bestellen.imono.nl/img/gerechten/5ebdaa310fa29_Big_Box_2.png"></div>
    <div class="card__particle"></div>
    <div class="card__detail">
      <h4 class="card__name">squirtle</h4>
    <div class="card__types">
        <div>water</div>
      </div>
    </div>
    <div class="card__number">#7</div>
  </div> -->

    </div>
  </div>
</template>


<script>
import { gsap } from "gsap"
export default {
  name: "recipes",
  mounted() {
    /*     const pageChange = new timeline({paused:true});
        const initAnim = new timeline({paused:true});
     */


  },
  data() {
    return {
      recipe: null
    }
  },
  methods: {
    close() {
      console.log('close')
      this.recipe = false;
      console.log(this.recipe)
    },
    openRecipe(val) {
      this.recipe = val;

/*       let tl = gsap.timeline();
      tl.from(".text", 1.8, {
        y: 100,
        ease: "power4.out",
        delay: 1,
        opacity: 0,
        stagger: {
          amount: 0.3
        }
      })
        .to(".text", 1.6, {
          opacity: 1,
          ease: "expo.inOut",
          stagger: 0.4
        })

     */}
  },
  components: {
    recipeDetails: () => import("@/components/RecipeDetails.vue"),

  },


}
</script>




<style>
.about {
  height: 100%;
  width: 100%;
  height: -webkit-fill-available;
  /* border: 2px solid; */
  overflow: auto;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  width: -webkit-fill-available;


}

#search-filter-form {
  margin: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  position: sticky;
  top: 0px;
  z-index: 4;
  /* padding: 0 10px; */
  /* padding-top: 40px; */



}

.form-search {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

.input-group {
  margin: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  border-radius: 16px;
  background: #fbfbfb;
  box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  padding: 10px 20px;
}

input::Placeholder {
  color: var(--gray-400);
}

.input-group input {
  flex: 1;
  outline: none;
  border: 0;
  background: transparent;
  font-size: 0.9rem;
  color: #383838;
  color: var(--gray-500);
  /* font-family: "Montserrat", sans-serif; */
}

.input-group button {
  margin-left: auto;
  background: transparent;
  border-radius: 20px;
  border: 0;
  color: #383838;
  color: var(--gray-400);
  cursor: pointer;
  outline: none;
  width: 32px;
  height: 32px;
  transition: ease all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}











#poke-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 30px;
  justify-items: stretch;
  align-items: stretch;
  padding-top: 20px;
  /* padding:16px; */
  /* overflow-y: scroll !important; */
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-scrolling: auto;
  padding-bottom: 140px;
  /* height: 75vh; */
  /* position: absolute; */
  top: 0;
  padding-bottom: 12em;
  /* padding-top: 78px; */
}

.card {
  display: flex;
  background: #fbfbfb;
  /* padding: 10px 20px; */
  padding-top: 18px;
  border-radius: 20px;
  position: relative;
  /* overflow: hidden; */
  flex-direction: column;
  align-items: center;
  transition: ease all 0.3s;
  box-shadow: 0 1px 2px rgb(0 0 0 / 2%), 0 2px 4px rgb(0 0 0 / 2%), 0 4px 8px rgb(0 0 0 / 2%), 0 8px 16px rgb(0 0 0 / 2%), 0 16px 32px rgb(0 0 0 / 2%), 0 32px 64px rgb(0 0 0 / 2%);
  transform: translatey(50px);
  height: 16em;
  height: auto;
  height:240px;
  justify-content: space-between;
}

.card:hover {
 /* background: white; */
  cursor: pointer;
}

/* .card:active,
.card:hover {
  background: #74a3ff78;
  cursor: pointer;
} */

.card__image {
  z-index: 1;
  margin-top: -5px;
  transition: ease all 0.3s;
  height: 80%;
  display: contents;
}

.card__image img {
  filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.1));
  max-width: 100%;
  height: 80%;
  width: -webkit-fit-content;
  object-fit: contain;
  width: 85%;
  height: 75%;
  height: 145px;
}



.card__number {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px 16px;
  font-family: Montserrat, sans-serif;
  color: #383838;
  background: rgba(255, 255, 255, 0.6);
  z-index: 3;
  border-radius: 13.3333333333px;
  font-size: 0.7rem;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.card__detail {
  width: -webkit-fill-available;
  /* background: red; */
  display: flex;
  flex-direction: column;
  padding: 12px;
  height: -webkit-fill-available;
  align-items: flex-start;
  /* padding-bottom: 0px; */
  justify-content: flex-start;
  height: auto;

  padding-left: 13px;
  gap: 8px;
  padding-top: 0px;
  padding-bottom:8px;
}

.card__name {
  font-family: Montserrat, sans-serif;
  font-size: 1.3em;
  font-weight: 700;
  color: #383838;
  text-transform: capitalize;
  /* mix-blend-mode: color-burn; */
  z-index: 3;
  text-align: center;
  position: relative;
  color: #010206c4;
  color: var(--gray-700);
  text-align: left;
font-family: Poppins;
font-weight: 600;
font-size: 16px;
}

.card__types {
  display: flex;
  justify-content: flex-start;
  /* margin: 10px 0; */
  width: -webkit-fill-available;
  align-items: center;
}

.card__types div {
  /* margin: 0 5px; */
  /* padding: 8px 8px; */
  font-family: Montserrat, sans-serif;
  color: #2f2f2fcc;
  background: rgba(255, 255, 255, 0.6);

  z-index: 3;
  border-radius: 12px;
  font-size: 12px;
  font-family: Poppins;
  -webkit-backdrop-filter: blur(5px);
  /* backdrop-filter: blur(5px); */
  font-weight: 500;
  text-transform: capitalize;


  padding: 5px 6px;
  font-size: 9px;
  background: #ff5fc42b;
  color: #ff0082c4;

  background: #d9d7e138;
  color: #636978d9;
  background: var(--gray-100);
  color: var(--gray-400);
  font-weight: 500;
  list-style: none;
  border-radius: 6px;
}
</style>