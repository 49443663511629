
<template>
  <div>
    <div class="">
      edmame
      <label>id</label>
      <input type="text" v-model="edmameID" />

      <label>key</label>
      <input type="text" v-model="edmameKey" />
    </div>

    <div class="">
      fs
      <label>id</label>
      <input type="text" v-model="fsID" />

      <label>key</label>
      <input type="text" v-model="fsKey" />
    </div>

  </div>
</template>

<script>
export default {
  name: 'Settings',
  created() {

  },
  data() {
    return {
    }
  },
  props: {

  },
  methods: {

  },
  computed: {
    edmameKey: {
      get() {
        return this.$store.getters['auth/api']('edmame').key
      },
      set(value) {
        return this.$store.dispatch("auth/setName", { api: 'edmame', name: 'key', val: value });
      }
    },
    edmameID: {
      get() {
        console.log(this.$store.getters['auth/api']('edmame').id)
        return this.$store.getters['auth/api']('edmame').id
      },
      set(value) {
        console.log(value)
        return this.$store.dispatch("auth/setName", { api: 'edmame', name: 'id', val: value });
      }
    },
    fsKey: {
      get() {
        return this.$store.getters['auth/api']('fs').key
      },
      set(value) {
        return this.$store.dispatch("auth/setName", { api: 'fs', name: 'key', val: value });
      }
    },
    fsID: {
      get() {
        console.log(this.$store.getters['auth/api']('fs').id)
        return this.$store.getters['auth/api']('fs').id
      },
      set(value) {
        console.log(value)
        return this.$store.dispatch("auth/setName", { api: 'fs', name: 'id', val: value });
      }
    },

  }
}
</script>

<style lang="scss" scoped>

</style>

